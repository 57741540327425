<template>
  <div>
    <BaseButton
      @click="modalOpen = true"
    >
      Export
      <Icon
        width="16px"
        height="16px"
        view-box="0 0 512 512"
        class="ml-1"
      >
        <Download />
      </Icon>
    </BaseButton>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Export {{ label ? label : type }}
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <div
        v-if="!inProgress"
        class="m-3"
      >
        <button
          class="m-3 text-secondary"
          @click="download('excel')"
        >
          Download Excel (xlsx)
        </button>
      </div>
      <Loader
        v-else
        class="py-12"
      />
    </Modal>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Download from '@components/Icons/Download'
import Loader from '@components/Loader'
import Modal from '@components/Modal'
import Plus from '@components/Icons/Plus'

import exportApi from '@api/export'

export default {
  components: {
    Icon,
    Download,
    Loader,
    Modal,
    Plus
  },

  props: {
    type: {
      type: String,
      required: true
    },

    /**
     * By default the type is displayed, but it might not be friendly,
     * so a label can be used to replace it
     */
    label: {
      type: String,
      default: null
    },

    /**
     * The id is required if we want to download an individual set or filter
     */
    id: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      modalOpen: false,
      inProgress: false
    }
  },

  methods: {
    /**
     * @param {string} format
     * @return {Promise}
     */
    download(format) {
      this.inProgress = true

      let type = this.type
      // Append key to type if we have one
      if (this.id) {
        type = type + '/' + this.id
      }

      return exportApi.download(type, format)
        .then(response => {
          this.createFileDownload(response)
          this.modalOpen = false
        })
        .catch(() => {
          console.error('Cannot download file')
        })
        .finally(() => {
          this.inProgress = false
        })
    },

    /**
     * @param {Object} Axios response
     */
    createFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.type + '.xlsx')
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>
